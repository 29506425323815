body {
  margin: 0;
  padding: 0;
  font-family: "Vazir", sans-serif;
  /* overflow-x: hidden; */

}
*{
  box-sizing: border-box;
}
