[class*="ring"], .center {
  position: absolute;
  top: 0;
}


section {
  cursor: pointer;
}

[class*="ring"] {
  scale: 0.75 0.75;
  @media (max-width : 1024px){
    scale : 0.72 0.72;
  }
  @media (max-width : 810px){
    scale : 0.6 0.6;
  }
  @media (max-width : 550px){
    scale : 0.5 0.5;
  }
  @media (max-width : 450px){
    scale : 0.4 0.4;
  }
  animation: glow 3s linear infinite;
  border-radius: 50%;
  border: 0.6em solid rgba(255, 255, 255, 0.8);
  border-top: 1em solid rgba(255, 255, 255, 0.8);
  border-bottom: 1em solid rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.3), inset 0 0 20px rgba(255, 255, 255, 0.3);
  height: 20em;
  transition: 1s transform ease-in-out;
  width: 20em;
}
[class*="ring"] div {
  animation: rotate 2s linear infinite;
  border-radius: 50%;
  height: 100%;
  position: relative;
  width: 100%;
}
[class*="ring"] div:after {
  animation: squash 2s linear infinite;
  -moz-transform: scale(3, 1);
  -ms-transform: scale(3, 1);
  -webkit-transform: scale(3, 1);
  transform: scale(3, 1);
  border-radius: 50%;
  content: '';
  left: 50%;
  height: 3em;
  margin-left: -1.5em;
  opacity: 0.8;
  position: absolute;
  top: -1.2em;
  width: 3em;
}

.ring1 {
  transform: rotate(120deg) scale(0.333, 1);
}
.ring1 div:after {
  background: #F4EE72;
  box-shadow: 0 0 20px #F4EE72;
}
section:hover .ring1 {
  border: 0.6em solid rgba(255, 255, 255, 0.8);
  transform: rotate(0deg) scale(0.33333, 0.33333) perspective(600px) rotateX(60deg);
}
section:hover .ring1 div:after {
  animation: none;
  top: -1.6em;
  transform: scale(1, 1);
}

.ring2 {
  transform: rotate(240deg) scale(0.333, 1);
}
.ring2 div:after {
  background: #5165AE;
  box-shadow: 0 0 20px #5165AE;
}
section:hover .ring2 {
  border: 0.6em solid rgba(255, 255, 255, 0.8);
  transform: rotate(0deg) scale(0.66667, 0.66667) perspective(600px) rotateX(60deg);
}
section:hover .ring2 div:after {
  animation: none;
  top: -1.6em;
  transform: scale(1, 1);
}

.ring3 {
  transform: rotate(360deg) scale(0.333, 1);
}
.ring3 div:after {
  background: #EE2924;
  box-shadow: 0 0 20px #EE2924;
}
section:hover .ring3 {
  border: 0.6em solid rgba(255, 255, 255, 0.8);
  transform: rotate(0deg) scale(1, 1) perspective(600px) rotateX(60deg);
}
section:hover .ring3 div:after {
  animation: none;
  top: -1.6em;
  transform: scale(1, 1);
}

.center {
  background: white;
  border-radius: 50%;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.3);
  height: 3em;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.3), inset 0 0 20px rgba(255, 255, 255, 0.3);
  transition: 1s transform ease-in-out;
  width: 3em;
}
section:hover .center {
  transform: translate(0, 0.5em);
}

@keyframes glow {
  0%, 100% {
    box-shadow: 0 0 0 rgba(255, 255, 255, 0.2), 0 0 10px rgba(255, 255, 255, 0.2), inset 0 0 20px rgba(255, 255, 255, 0.3);
  }
  50% {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.2), 0 0 30px rgba(255, 255, 255, 0.2), inset 0 0 20px rgba(255, 255, 255, 0.3);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes squash {
  0%,50%, 100% {
    transform: scale(1, 1);
    top: -2.6em;
  }
  25%, 75% {
    transform: scale(2, 1);
    top: -1.2em;
  }
}
